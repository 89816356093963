<template lang="pug">
	#Noticias
		.filterContainer
			p.title O que está buscando?
			.top
				input(v-model="search" placeholder="Notícia")
				FontAwesomeIcon(:icon="icons.faMagnifyingGlass").searchIcon
			.bottom
				p De:
				.searchField
					input(type="date" v-model="startDate")
				p Até:
				.searchField
					input(type="date" v-model="endDate")
			.hr
				hr
		Spinner(v-if="displayNews.length === 0 && search == ''" id="spinner")
		ul
			li(v-for="noticias, index in displayNews" v-if="index < displayedNews && displayNews.length > 0")
				router-link(:to="{name: 'Noticia', params: {slug: noticias.translations[translationIndex].slug}}")
					.img
						img(:src="noticias.translations[translationIndex].thumb").image
					.container
						.title
							h3 {{ noticias.translations[translationIndex].title }}
						.wrapper
							.bloco 
								p.data {{ formatDate(noticias.translations[translationIndex].publishedAt) }}
								p.conteudo {{ noticias.translations[translationIndex].description }}
								router-link(:to="{name: 'Noticia', params: {slug: noticias.translations[translationIndex].slug}}").squareButton LEIA MAIS
		button(@click="expandNews" v-if="displayNews.length > 0 && showButton").squareButton MAIS NOTÍCIAS
		h2(v-if="displayNews.length === 0 && search !== ''") Não foram encontrados resultados.
</template>

<script> 
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Spinner from '@components/Spinner/Spinner'

export default {
	name: "noticias",
	props: {
		contents: {
			type: Object,
		}
	},
	components: {
		Spinner
	},
	data() {
		return {
			search: '',
			startDate: null,
			endDate: null,
			translationIndex: 0,
			showButton: true,
			paginatedNews: [],
			displayedNews: 4,
			step: 4,
			icons: {
				faMagnifyingGlass
			},
			allNews: [],
		}
	},
	methods: {
		searchOptions(option) {
			let formattedOption = option ? option.toLowerCase() : ''
			if (formattedOption.includes(this.search.toLowerCase()))
				return true
			return false
		},
		formatDate(date) {
			const objData = new Date(date + 'T03:00:00')
			let month = objData.getMonth()
			if (month < 9) {
				month = `0${month + 1}`
			}
			else {
				month += 1
			}
			let formatted = `${objData.getDate()}/${month}/${objData.getFullYear()}`
			return formatted
		},
		compareDate(date) {
			if (this.startDate !== null && this.startDate >= date) return false
			if (this.endDate !== null && this.endDate <= date) return false
			return true
		},
		async expandNews() {
			this.displayedNews += this.step;

			if (!this.search && !this.startDate && !this.endDate) {
				try {
					await this.$store.dispatch('fetchNews', { skip: this.paginatedNews.length, take: this.step });
					const newNews = this.$store.state.news

					if (newNews && newNews.length > 0) {
						this.paginatedNews.push(...newNews);
						this.showButton = newNews.length === this.step;
					} else {
						this.showButton = false;
					}
				} catch (error) {
					console.error("Erro ao carregar as notícias:", error);
				}
			} else {
				this.searchNews(true);
			}
		},
		async searchNews(expand = false) {
			if (!expand) this.displayedNews = 4;
			const filters = {
				q: this.search || '',
				skip: expand ? this.paginatedNews.length : 0,
				take: expand ? this.step : this.displayedNews,
			};

			if (this.startDate) {
				filters.startDate = this.startDate;
			}
			if (this.endDate) {
				filters.endDate = this.endDate;
			}

			try {
				await this.$store.dispatch('fetchSearchNews', filters);
				const fetchedNews = this.$store.state.searchNews || [];

				if (expand) {
					this.paginatedNews.push(...fetchedNews);
				} else {
					this.paginatedNews = fetchedNews;
				}

				this.showButton = fetchedNews.length === this.step;
			} catch (error) {
				console.error("Erro ao buscar notícias:", error);
			}
		},
		async fetchInitialNews() {
			this.displayedNews = 4;
			await this.$store.dispatch('fetchNews', { skip: 0, take: this.displayedNews });

			const initialNews = this.$store.state.news;
			if (initialNews && initialNews.length > 0) {
				this.paginatedNews = initialNews;
				this.showButton = initialNews.length === this.displayedNews;
			} else {
				this.paginatedNews = [];
				this.showButton = false;
			}
		},
	},
	watch: {
		search: function () {
			this.searchNews();
		},
		startDate: function () {
			this.searchNews();
		},
		endDate: function () {
			this.searchNews();
		}
	},
	computed: {
		displayNews() {
			return this.paginatedNews.map(news => ({
				...news,
				translations: news.translations.map(translation => ({
					...translation,
					thumb: translation.thumb ? translation.thumb.filePath : '',
					publishedAt: translation.publishedAt.slice(0, 10),
				}))
			})).slice(0, this.displayedNews);
		}
	},
	mounted () {
		if (this.$route.query.search) {
			this.search = this.$route.query.search
		}
	},
	created() {
		this.fetchInitialNews()
	},
}
</script>

<style lang="stylus" scoped src="./Noticias.styl"></style>
